<script lang="ts" setup>
import { computed, toRefs } from 'vue'
import type { ConcreteComponent } from 'vue'
import type { UiButtonVariant } from '~/types/ui'
import { NuxtLink } from '#components'

const props = withDefaults(
  defineProps<{
    variant?: UiButtonVariant
    disabled?: boolean
    tag?: string | ConcreteComponent
    type?: string
  }>(),
  {
    variant: 'sky',
    disabled: false,
    tag: null,
    type: 'button',
  },
)

const variantClasses = {
  sky: 'bg-sky text-white border border-2 border-sky shadow-sm hover:bg-sky-900 hover:border-sky-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-900',
  jungle:
    'bg-jungle text-white border border-2 border-jungle shadow-sm hover:bg-jungle-900 hover:border-jungle-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-jungle-900',
  white:
    'bg-white text-black shadow-sm border border-2 border-sky-900 hover:bg-sky-900 hover:text-white focus-visible:bg-sky-900 focus-visible:text-white focus-visible:outline-0',
}

const { tag, disabled } = toRefs(props)

const tagWithDefaults = computed(() => tag?.value || NuxtLink)
</script>

<template>
  <component
    :is="tagWithDefaults"
    :type="type"
    :disabled="disabled"
    class="inline-flex items-center justify-center rounded-full px-6 py-2.5 text-base font-semibold transition-colors focus-visible:outline disabled:cursor-not-allowed disabled:border-disabled-300 disabled:bg-disabled-300 disabled:text-disabled-500 disabled:shadow-none disabled:ring-0"
    :class="[variantClasses[variant]]"
    data-testid="button">
    <slot v-if="$slots.prefix" name="prefix" />
    <slot />
    <slot v-if="$slots.suffix" name="suffix" />
  </component>
</template>
